// Open the Modal
function openModal() {
  document.getElementById("teaserModal").style.display = "flex";
}

// Close the Modal
function closeModal() {
  document.getElementById("teaserModal").style.display = "none";
}

var slideIndex = 1;

// Next/previous controls
function plusSlides(n) {
  showSlides(slideIndex += n);
}

// Thumbnail image controls
function currentSlide(n) {
  showSlides(slideIndex = n);
}

function showSlides(n) {
  var i;
  var slides = document.getElementsByClassName("teaserModal__item");
  if (n > slides.length) {slideIndex = 1}
  if (n < 1) {slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  // TypeError: t[(slideIndex - 1)] is undefined -- but why?
  slides[slideIndex - 1].style.display = "block";

  document.onkeydown = function(evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27) {
        closeModal();
    }
    if (evt.keyCode == 37) {
        plusSlides(-1);
    }
    if (evt.keyCode == 39) {
        plusSlides(+1);
    }
  };
}

$('.m-teaserslider__item-info').click(function() {
  $(this).closest('figure').find('.m-teaserslider__item-infomore').show(200);
});

$('.m-teaserslider__item-close').click(function() {
  $(this).closest('figure').find('.m-teaserslider__item-infomore').hide(200);
});


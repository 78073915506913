$('.js-accordion-title').click(function(e) {
  $(this).toggleClass('is-open');
  $(this).next().slideToggle(300);
  $(this).next().toggleClass('is-open');
});

$('.m-features__item').on('click', function(){
  var item_id = $(this).attr('class').substring(35);
  $('.m-popup').fadeOut(0);
  $(this).closest('.js-accordion-panel').find('.m-popup--' + item_id).fadeIn(600);
  $('html').css({'overflow':'hidden'});
});

$('.m-popup').on('click', function() {
  $('.m-popup').fadeOut(600);
  $('html').removeAttr('style');  
});
/**
 * WP megamenu Toggler
 * -------------------------------------------------- */

// Initially remove is-open class
$('.js-flyout').removeClass('is-open');

if ($(window).width() < 1280) {

  $('.menu-item.has-flyout > a').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var clickedItem = $(this);
    var clickedItemParent = clickedItem.parent('.has-flyout');
    var clickedItemFlyout = clickedItem.next('.js-flyout');
    var allOpenFlyouts = $('.js-flyout.is-open');

    // Handling open/closing states
    if (clickedItemFlyout.hasClass('is-open')) {
      clickedItemParent.removeClass(
        'has-open-flyout'); // [1] Counterparts, change Menu-Item state
      clickedItemFlyout.removeClass('is-open').addClass('is-closed');
      $('header').removeClass('active-flyout');
    } else {
      allOpenFlyouts
        .removeClass('is-open')
        .addClass('is-closed')
        .parent('.has-flyout')
        .removeClass(
          'has-open-flyout'); // [1] Counterparts, change Menu-Item state
      clickedItemParent.addClass(
        'has-open-flyout'); // [1] Counterparts, change Menu-Item state
      clickedItemFlyout.removeClass('is-closed').addClass('is-open');
      $('header').addClass('active-flyout');
    }
  });

} else {

  // Show
  $('.menu-item.has-flyout > a').on('mouseenter', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var clickedItem = $(this);
    var clickedItemParent = clickedItem.parent('.has-flyout');
    var clickedItemFlyout = clickedItem.next('.js-flyout');
    var allOpenFlyouts = $('.js-flyout.is-open');

    // Handling open/closing states
    if (clickedItemFlyout.hasClass('is-open')) {
      clickedItemParent.removeClass(
        'has-open-flyout'); // [1] Counterparts, change Menu-Item state
      clickedItemFlyout.removeClass('is-open').addClass('is-closed');
      $('header').removeClass('active-flyout');
    } else {
      allOpenFlyouts
        .removeClass('is-open')
        .addClass('is-closed')
        .parent('.has-flyout')
        .removeClass(
          'has-open-flyout'); // [1] Counterparts, change Menu-Item state
      clickedItemParent.addClass(
        'has-open-flyout'); // [1] Counterparts, change Menu-Item state
      clickedItemFlyout.removeClass('is-closed').addClass('is-open');
      $('header').addClass('active-flyout');
    }
  });

  // Hide it when leave mouse and move outside
  $('main, .l-header__nav-logo, .l-header__nav-meta').on('mouseover', function (e) {
    if ($('.js-flyout').hasClass('is-open')) {
      if (!$(e.target).closest('.js-flyout').length) {
        $('.js-flyout').removeClass('is-open');
        $('.js-flyout')
          .parent('.has-flyout')
          .removeClass('has-open-flyout'); // change Menu-Item state
      }
      $('header').removeClass('active-flyout');
    }
  });
  
  $('header').on('mouseleave', function (e) {
    if ($('.js-flyout').hasClass('is-open')) {
      if (!$(e.target).closest('.js-flyout').length) {
        $('.js-flyout').removeClass('is-open');
        $('.js-flyout')
          .parent('.has-flyout')
          .removeClass('has-open-flyout'); // change Menu-Item state
      }
      $('header').removeClass('active-flyout');
    }
  });

}

// Close it when clicked the cross icon
$('.js-close-flyout').on('click', function (e) {
  $(this)
    .parent('.js-flyout')
    .removeClass('is-open');
  $(this)
    .parents('.has-flyout')
    .removeClass('has-open-flyout'); // change Menu-Item state
  $('header').removeClass('active-flyout');
  $('.l-flyout').removeClass('is-open');
});

// Close it when clicked outside
$(document).on('click', function (e) {
  if ($('.js-flyout').hasClass('is-open')) {
    if (!$(e.target).closest('.js-flyout').length) {
      $('.js-flyout').removeClass('is-open');
      $('.js-flyout')
        .parent('.has-flyout')
        .removeClass('has-open-flyout'); // change Menu-Item state
    }
    $('header').removeClass('active-flyout');
  }
});

// Close it when pressed esc-key
$(document).keydown(function (e) {
  if ($('.js-flyout').hasClass('is-open') && e.which == 27) {
    $('.js-flyout').removeClass('is-open');
    $('.js-flyout')
      .parent('.has-flyout')
      .removeClass('has-open-flyout'); // change Menu-Item state
    $('header').removeClass('active-flyout');
  }
});

/* Fullscreen Burger Navigation */

var $menuToggle = $('.js-menu-toggle');
var $mobileMenu = $('#js-menu');
var menuOpenClass = 'menu-is-open';
var menuToggleOpenClass = 'is-open';
var menuToggleClosingClass = 'is-closing';

// FUNCTION: Open/close mobile menu, animation via CSS
function toggleMobileMenu() {
  $mobileMenu.toggleClass(menuOpenClass);
  $('body').toggleClass(menuOpenClass);
}

// FUNCTION: Open/close burger, animation via CSS
function toggleMobileMenuButton() {
  // Toggle menuToggleOpenClass by click
  $menuToggle.toggleClass(menuToggleOpenClass);
  // If toggle does NOT have menuToggleOpenClass close it
  if (!$menuToggle.hasClass(menuToggleOpenClass)) {
    $menuToggle.addClass(menuToggleClosingClass);
    setTimeout(function () {
      $menuToggle.removeClass(menuToggleClosingClass);
    }, 350);
  }
}

// Open/close menu an burger by click
$menuToggle.on('click', function (e) {
  e.preventDefault();
  e.stopPropagation();
  toggleMobileMenu(); // Open mobile menu
  toggleMobileMenuButton(); // Open burger
});

// Close overlay wih esc-key
$(document).keydown(function (e) {
  if ($mobileMenu.hasClass(menuOpenClass) && e.which == 27) {
    toggleMobileMenu(); // Open mobile menu
    toggleMobileMenuButton(); // Open burger
  }
});
/** =============================================================================
OWL SLIDER
============================================================================ */

$(document).ready(function () {
  init_sliders();
  // $('.unflow').closest('section').addClass('u-overflow--hidden');
});

// Pad a number with leading zeroes.
// Helper Funktion um einen Zahlen-Pager zu erstellen ( <- 1 2 3 4 -> )
function pad(n, len) {
  s = n.toString();
  if (s.length < len) {
    s = ('0000000000' + s).slice(-len);
  }

  return s;
}

function init_sliders() {

  m_slider_slider();
  m_quotes_slider();
  m_teaser_media_slider();
  m_teaserslider_slider();
  m_textimage_media_slider();
  m_textimage_slider_slider();
  m_designsystem_slider();
  m_hero_home_slider();

}

// Slider

function m_slider_slider() {

  rundj_slider01 = $('.m-slider');

  rundj_slider01.each(function () {

    $wrapper = $(this);
    var owl = $wrapper.find('>.owl-carousel');
    var owl_options_additional = owl.data("owl-carousel");
    var owl_options_items = owl.data("owl-carousel").first;

    var owl_options = {
      items: 1,
      stagePadding: 50,
      loop: false,
      rewind: false,
      margin: 0,
      nav: true,
      dots: true,
      smartSpeed: 440,
      lazyLoad: true,
      autoplay: false,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      navText: [
        '<div class="owl-chevron owl-chevron-left"></div>',
        '<div class="owl-chevron owl-chevron-right"></div>'
      ],
      dotsClass: 'owl-dots m-slider__dots',
      responsiveClass: true,
      responsive: {
        0: {
          items: 1
        },
        640: {
          items: 2
        },
        768: {
          items: 3
        },
        1280: {
          items: owl_options_items
        }
      }
    };

    $.extend(owl_options, owl_options_additional);

    // Owl Slider initiieren
    owl.owlCarousel(owl_options);

    // COUNTER

    function counter(event) {
      if (!event.namespace) {
        return;
      }
      var slides = event.relatedTarget;
      $('.m-slider__counter').text(slides.relative(slides.current()) + 1 +
        '/' + slides.items().length);
    }

  });

}

// Quote-Slider

function m_quotes_slider() {

  $('.m-quotes').owlCarousel({
    loop: false,
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    navText: [
      '<div class="owl-chevron owl-chevron-left"></div>',
      '<div class="owl-chevron owl-chevron-right"></div>'
    ],
    dots: true,
  })

}

// Teaser-Slider

function m_teaser_media_slider() {

  $('.m-teaser__media .owl-carousel').owlCarousel({
    loop: false,
    items: 1,
    margin: 0,
    nav: true,
    navText: [
      '<div class="owl-chevron owl-chevron-left"></div>',
      '<div class="owl-chevron owl-chevron-right"></div>'
    ],
    dots: true
  })

}

// Teaser-Slider Image

function m_teaserslider_slider() {

  teaser_slider_image = $('.m-teaserslider');

  teaser_slider_image.each(function () {

    $wrapper = $(this);
    var owl = $wrapper.find('>.owl-carousel');
    var owl_options_additional = owl.data("owl-carousel");
    var owl_options_items = owl.data("owl-carousel").first;

    var owl_options = {
      items: 1,
      stagePadding: 50,
      loop: false,
      rewind: false,
      margin: 0,
      nav: true,
      dots: false,
      smartSpeed: 440,
      lazyLoad: true,
      autoplay: false,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      navText: [
        '<div class="owl-chevron owl-chevron-left"></div>',
        '<div class="owl-chevron owl-chevron-right"></div>'
      ],
      responsiveClass: true,
      responsive: {
        0: {
          items: 1
        },
        640: {
          items: 2
        },
        768: {
          items: 3
        },
        1280: {
          items: owl_options_items
        }
      }
    };

    $.extend(owl_options, owl_options_additional);

    // Owl Slider initiieren
    owl.owlCarousel(owl_options);

  });

}

// Text/Image-Slider

function m_textimage_media_slider() {

  $('.m-textimage__media .owl-carousel').owlCarousel({
    loop: false,
    items: 1,
    margin: 0,
    nav: true,
    navText: [
      '<div class="owl-chevron owl-chevron-left"></div>',
      '<div class="owl-chevron owl-chevron-right"></div>'
    ],
    dots: true
  })

}

// Text/Image-Slider

function m_textimage_slider_slider() {

  $('.m-textimage-slider .owl-carousel').owlCarousel({
    loop: false,
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    navText: [
      '<div class="owl-chevron owl-chevron-left"></div>',
      '<div class="owl-chevron owl-chevron-right"></div>'
    ],
    dots: true
  })

}

// Design System Slider

function m_designsystem_slider() {

  $('.m-designsystem .owl-carousel').owlCarousel({
    loop: false,
    items: 1,
    margin: 0,
    nav: true,
    navText: [
      '<div class="owl-chevron owl-chevron-left"></div>',
      '<div class="owl-chevron owl-chevron-right"></div>'
    ],
    dots: true
  })

}

// Hero-Slider

function m_hero_home_slider() {

  $('.m-hero--home .owl-carousel').owlCarousel({
    loop: false,
    items: 1,
    margin: 0,
    nav: true,
    navText: [
      '<div class="owl-chevron owl-chevron-left"></div>',
      '<div class="owl-chevron owl-chevron-right"></div>'
    ],
    dots: true
  })

}